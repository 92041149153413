import "./antdStyles.scss";

import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import BasicLayout from "./layouts/BasicLayout";
import UserLayout from "./layouts/UserLayout";
import PeopleEditor from "./pages/DataEditor/PeopleEditor";
import CompanyEventsEditor from "./pages/DataEditor/CompanyEventsEditor";
import InsiderEventsEditor from "./pages/DataEditor/InsiderEventsEditor";
import CompanyEditor from "./pages/DataEditor/CompanyEditor";
import ProfileEdits from "./pages/DataEditor/ProfileEdits";
import UsersEditor from "./pages/UserEditor/UsersEditor";
import UserMappingEditor from "./pages/UserEditor/UserMappingEditor";
import CreditsLog from "./pages/UserEditor/CreditsLog";
import UserSetupResponse from "./pages/UserEditor/UserSetupResponse";
import ReferrerCode from "./pages/ReferrerCode";
import WeeklyTip from "./pages/Dashboard/WeeklyTip";
import SuggestedVideo from "./pages/Dashboard/SuggestedVideo";
import Account from "./pages/Account";
import Comments from "./pages/Comments";
import TeamEditor from "./pages/TeamEditor";
import ContactExport from "./pages/ImportExport/ContactExport";
import ContactImport from "./pages/ImportExport/ContactImport";
import CrmExportTracks from "./pages/ImportExport/CrmExportTracks";
import Reports from "./pages/Reports";

import Login from "./pages/User/Login";
import LoginCallBack from "./pages/User/LoginCallBack";
import ForgotPassword from "./pages/User/ForgotPassword";
import ForgotPasswordSent from "./pages/User/ForgotPasswordSent";
import Exception from "./pages/Exception";

import Progress from "./components/Progress";

import { requestWithAuth } from "./services/api";
import { setAxiosTokenInterceptor } from "./components/Auth0AxiosInterceptor";
import CompanyMerge from "./pages/DataEditor/CompanyMerge";

const App = () => {
  const [hasToken, setHasToken] = useState(false);
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [name, setName] = useState("");
  const [userType, setUserType] = useState("");
  const [isNexisAdmin, setIsNexisAdmin] = useState(false);

  /**
   * Check if the user is already authenticated
   * Wait untill @isLoading is completed
   * (otherwise @isAuthenticated won't be updated correctly)
   * else if @isAuthenticated get the token and
   * set it in the axios header and after that
   * load the components.
   */
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      try {
        getIdTokenClaims().then((token) => {
          if (token) setAxiosTokenInterceptor(token.__raw);
          requestWithAuth("user-type", "GET", null, null).then((response) => {
            if (response && response.results && response.results[0]) {
              setName(response.results[0].full_name);
              setUserType(response.results[0].user_type);
              if (response.results[0].nexis_admin === true)
                setIsNexisAdmin(true);
            }
            setHasToken(true);
          });
        });
      } catch (error) {
        console.log(error);
      }
    } else if (!isLoading) {
      setHasToken(true);
    }
  }, [isLoading, isAuthenticated, getIdTokenClaims]);

  if (isLoading || !hasToken) return <Progress />;

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <BasicLayout
              userName={name}
              userType={userType}
              isNexisAdmin={isNexisAdmin}
            />
          }
        >
          <Route path="data-editor">
            <Route path="vault" element={<PeopleEditor />} />
            <Route path="org-events" element={<CompanyEventsEditor />} />
            <Route path="insider-events" element={<InsiderEventsEditor />} />
            <Route path="company" element={<CompanyEditor />} />
            <Route path="company-merge" element={<CompanyMerge />} />
            <Route path="profile-edits" element={<ProfileEdits />} />
            <Route index element={<Navigate to="/data-editor/vault" />} />
          </Route>
          <Route path="user-editor">
            <Route
              path="users"
              element={<UsersEditor isNexisAdmin={isNexisAdmin} />}
            />
            <Route path="users-mapping" element={<UserMappingEditor />} />
            <Route path="credits-log"  element={<CreditsLog/>} />
            <Route
                  path="user-setup-response"
                  element={<UserSetupResponse />}
                />
            <Route index element={<Navigate to="/user-editor/users" />} />
          </Route>

          <Route
            path="referrercode"
            element={<ReferrerCode isNexisAdmin={isNexisAdmin} />}
          />
          <Route path="dashboard">
            <Route path="weekly-tip" element={<WeeklyTip />} />
            <Route path="suggested-video" element={<SuggestedVideo />} />
            <Route index element={<Navigate to="/dashboard/weekly-tip" />} />
          </Route>
          <Route path="comments" element={<Comments />} />
          <Route
            path="team-editor"
            element={<TeamEditor isNexisAdmin={isNexisAdmin} />}
          />
          <Route path="import-export">
            <Route path="contact-exports" element={<ContactExport isNexisAdmin={isNexisAdmin}/>} />
            <Route path="contact-imports" element={<ContactImport isNexisAdmin={isNexisAdmin}/>} />
            <Route path="crm-export-tracks" element={<CrmExportTracks />} />
            <Route
              index
              element={<Navigate to="/import-export/contact-exports" />}
            />
          </Route>
          <Route path="reports" element={<Reports />} />
          <Route path="account">
            <Route path="settings/base" element={<Account name={name} />} />
            <Route
              path="settings"
              element={<Navigate to="/account/settings/base" />}
            />
            <Route index element={<Navigate to="/account/settings/base" />} />
          </Route>
          <Route
            index
            element={
              <Navigate
                to={isNexisAdmin ? "/user-editor/users" : "/data-editor/vault"}
              />
            }
          />
          <Route path="*" element={<Exception />} />
        </Route>
        <Route path="/user/" element={<UserLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="login/callback" element={<LoginCallBack />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="forgot-password-sent" element={<ForgotPasswordSent />} />
          <Route index element={<Navigate to="/user/login" />} />
        </Route>
        <Route path="/exception" element={<Exception />} />
      </Routes>
    </Router>
  );
};

export default App;
