import "../index.scss";

import React, { useCallback, useEffect, useState } from "react";
import { Spin } from "antd";
import moment from "moment";

import { requestWithAuth } from "../../services/api";
import TopSection from "../../components/TopSection";
import CommonTable from "../../components/CommonTable";
import ExportCsv from "../../components/ExportCsv";

let params = {};
const Reports = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [reportsCount, setReportsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const [sortedInfo, setSortedInfo] = useState({
    column: "",
    order: "",
  });
  const { column, order } = sortedInfo;
  const { pageSize, current } = listPagination;

  // let searchQuery = '';

  const fetchReports = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth("vault-report", "GET", newParams, null).then((response) => {
      if (response && response.results) {
        setReportsData(response.results);
        setReportsCount(response.count);
        setListPagination((prevState) => ({
          ...prevState,
          total: response.count,
        }));
        setIsLoading(false);
      }
    });
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchReports();

    // return () => {
    //   params = {};
    //   setSortedInfo({
    //     column: '',
    //     order: ''
    //   });

    //   // searchQuery = '';
    //   setSearchQuery('');
    // }
  }, [fetchReports, column, order]);
  useEffect(() => {
    return () => {
      params = {};
    };
  }, []);

  const handleSearchQuery = (searchValue) => {
   
    setSearchQuery(searchValue || "");
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
    
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === "descend" ? "-" + sorter.columnKey : sorter.columnKey;
      setSortedInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortedInfo({
        column: "",
        order: "",
      });
      delete params.ordering;
    }

    // if (
    //     pagination.pageSize === listPagination.pageSize &&
    //     pagination.current === listPagination.current
    // ) {
    //     fetchReports();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const handleFormReset = () => {
    params = {};
    setSortedInfo({
      column: "",
      order: "",
    });

    setListPagination({
      current: 1,
      pageSize: 50,
    });

    // searchQuery = '';
    setSearchQuery("");
    //fetchReports();
  };

  const columns = [
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      width: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "last_login" && sortedInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Last Mobile Login",
      dataIndex: "latest_mobile_usage",
      key: "latest_mobile_usage",
      minWidth: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder:
        sortedInfo.column === "latest_mobile_usage" && sortedInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Last Chrome Login",
      dataIndex: "latest_web_usage",
      key: "latest_web_usage",
      minWidth: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "latest_web_usage" && sortedInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "create_time",
      key: "create_time",
      width: 125,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "create_time" && sortedInfo.order,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Last Import",
      dataIndex: "latest_import_created",
      key: "latest_import_created",
      width: 125,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      minWidth: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "email" && sortedInfo.order,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "first_name" && sortedInfo.order,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "last_name" && sortedInfo.order,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      minWidth: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "title" && sortedInfo.order,
    },
    {
      title: "Company",
      dataIndex: "org_name",
      key: "org_name",
      minWidth: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "org_name" && sortedInfo.order,
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      width: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "user_type" && sortedInfo.order,
    },
    {
      title: "Account Rep",
      dataIndex: "account_rep_email",
      key: "account_rep_email",
      minWidth: 130,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "account_rep_email" && sortedInfo.order,
    },
    {
      title: "Theme",
      dataIndex: "theme",
      key: "theme",
      width: 130,
    },
    {
      title: "B2b Id",
      dataIndex: "b2b_id",
      key: "b2b_id",
      width: 130,
    },
    {
      title: "B2c Id",
      dataIndex: "b2c_id",
      key: "b2c_id",
      width: 130,
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      width: 150,
    },
    {
      title: "Referral Code",
      dataIndex: "user_ref_code",
      key: "user_ref_code",
      width: 150,
    },
    {
      title: "Referral Code Created",
      dataIndex: "user_ref_code_created",
      key: "user_ref_code_created",
      minWidth: 130,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Referral Code Expires",
      dataIndex: "user_ref_code_expiry_date",
      key: "user_ref_code_expiry_date",
      minWidth: 130,
      render: (val) => {
        return (
          <div
            style={{ width: "100%", wordBreak: "break-word", display: "block" }}
          >
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },
    {
      title: "Referral Code Is Corporate",
      dataIndex: "user_ref_code_is_corporate",
      key: "user_ref_code_is_corporate",
      minWidth: 130,
    },
    {
      title: "Referral Code Is Active",
      dataIndex: "user_ref_code_is_active",
      key: "user_ref_code_is_active",
      minWidth: 130,
    },
    {
      title: "Contacts Matched",
      dataIndex: "total_matched_record_count",
      key: "total_matched_record_count",
      minWidth: 130,
    },
    {
      title: "Contacts Imported",
      dataIndex: "total_imported_record_count",
      key: "total_imported_record_count",
      minWidth: 130,
    },
    {
      title: "Comments",
      dataIndex: "user_ref_code_comment",
      key: "user_ref_code_comment",
      idth: 160,
    },
  ];

  const renderExportComponent = () => {
    return (
      <ExportCsv
        totalCount={reportsCount}
        searchQuery={searchQuery}
        url="vault-report"
        downloadFileName="Report_Table"
      />
    );
  };

  return (
    <>
      <div>
        <TopSection
          placeholder="Search First Name, Last Name, Email, Title, Company, Industry"
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          renderExportComponent={renderExportComponent}
          showSearch
          showNewButton={false}
          showExportCsv={reportsCount > 0}
          count={reportsCount}
          searchValue={searchQuery}          
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={reportsData}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => rec.id}
          />
        </Spin>
      </div>
    </>
  );
};

export default Reports;
