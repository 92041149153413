import "../index.scss";

import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Popconfirm,
  Input,
  DatePicker,
  Divider,
  Checkbox,
  message,
  Spin,
  Modal,
  Select,
  InputNumber,
  Button,
} from "antd";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import { requestWithAuth } from "../../services/api";
import { industryList } from "../../utils/constants";
import CommonTable from "../../components/CommonTable";
import TopSection from "../../components/TopSection";
import { EditableCell, EditableRow } from "../../components/EditableComponents";

let params = {};
//let searchQuery = "";

const FormItems = ({
  formVals,
  form,
  accountReps,
  isUpdate,
  isNexisAdmin,
  plansLookUp,
  userTypesLookup,
}) => {
  const { TextArea } = Input;
  const [teamsLookup, setTeamsLookup] = useState([]);
  const [teamsLoading, setTeamsLoading] = useState(false);

  const onTeamSearch = (searchQuery) => {
    if (!searchQuery) {
      setTeamsLookup([]);
      return;
    }
    setTeamsLoading(true);
    const params = {
      page_size: 50,
      page: 1,
      q: searchQuery,
    };

    setTeamsLookup([]);
    requestWithAuth("vault-team", "GET", params, null).then((response) => {
      if (response && response.results) {
        let options = response.results.map((ele) => ({
          value: ele.id,
          label: ele.name,
        }));
        setTeamsLookup([...options]);
      } else {
        setTeamsLookup([]);
      }
    });
    setTeamsLoading(false);
  };

  return (
    <>
      <Form.Item
        name="owner"
        label="Owner"
        required
        rules={[
          {
            required: true,
            message: "Enter Owner",
          },
        ]}
      >
        <Select
          style={{ width: "100%" }}
          showSearch
          filterOption={(input, option) => option.children.includes(input)}
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
        >
          {accountReps.map(({ value, text }) => (
            <Select.Option key={value} value={value}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="referrer_code"
        label="Referrer Code"
        required
        rules={[
          {
            required: true,
            message: "Enter Referrer Code",
          },
        ]}
      >
        <Input placeholder="Referrer Code" />
      </Form.Item>
      <Form.Item
        name="is_northwestern_mutual"
        label="NM User"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item name="company" label="Company">
        <Input placeholder="Company" />
      </Form.Item>

      <Form.Item name="industry" label="Industry">
        <Select
          style={{ width: "100%" }}
          showSearch
        >
          {industryList.map(({ value, text }) => (
            <Select.Option key={value} value={text}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="user_type" label="User Type">
        <Select style={{ width: "100%" }}>
          {userTypesLookup.map(({ value, text }) => (
            <Select.Option key={value} value={text}>
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="created" label="Created">
        <DatePicker placeholder="Created" disabled />
      </Form.Item>

      <Form.Item
        name="expiry_date"
        label="Expiration Date"
        required
        rules={[
          {
            required: true,
            message: "Enter Expiry",
          },
        ]}
      >
        <DatePicker
          placeholder="Expiration Date"
          disabledDate={(current) => {
            return isUpdate ? false : moment().add(-1, "days") >= current;
          }}
        />
      </Form.Item>

      {/**Do not show nexis client field for nexis users
       * but it should default to “yes” for all codes they create.
       * Make visible for Aidentified admin users so that
       *  we can update old referral codes for Nexis.
       */}
      {!isNexisAdmin && (
        <Form.Item name="is_nexis" label="Nexis Client" valuePropName="checked">
          <Checkbox />
        </Form.Item>
      )}
      {/* <Form.Item
                name="max_referrals"
                label="Maximum Number of Users"
                initialValue={formVals.max_referrals}>
                <Input placeholder="Maximum Number of Users" type="number" />
            </Form.Item>, */}

      <Form.Item name="max_referrals" label="Maximum Number of Users">
        <InputNumber
          style={{ width: "100%" }}
          placeholder="Maximum Number of Users"
          autoComplete="_away"
        />
      </Form.Item>

      {/* <Form.Item
                name="referrer_code_credits"
                label="Credits"
                initialValue={isUpdate ? formVals.referrer_code_credits : 20}>
                <Input placeholder="Credits" type="number" />
            </Form.Item>, */}

      <Form.Item name="referrer_code_credits" label="Credits">
        <InputNumber
          style={{ width: "100%" }}
          placeholder="Credits"
          autoComplete="_away"
        />
      </Form.Item>

      <Form.Item
        name="is_corporate"
        label="Invoice Only"
        valuePropName="checked"
      >
        <Checkbox style={{ lineHeight: "32px" }} />

        {/* <Input type="checkbox" value={!!formVals.is_corporate} /> */}
      </Form.Item>

      <Form.Item name="maxio_public_signup_url" label="Maxio Plan">
        <Select style={{ width: "100%" }}>
          {plansLookUp.map(({ value, label }) => (
            <Select.Option key={label} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="team_obj" label="Auto add to team">
        <CreatableSelect
          loading={teamsLoading}
          className={"team-select"}
          placeholder="Search Team"
          options={teamsLookup}
          isSearchable
          isClearable
          isValidNewOption={() => false}
          hideSelectedOptions
          cropWithEllipsis
          closeMenuOnSelect
          closeMenuOnScroll
          onInputChange={(e) => onTeamSearch(e)}
          onBlur={() => {
            setTeamsLookup([]);
          }}
          noOptionsMessage={() => null}
        />
      </Form.Item>
      <Form.Item name="comment" label="Comments">
        <TextArea placeholder="Comments" />
      </Form.Item>
    </>
  );
};

const FormModal = ({
  isUpdate,
  handleUpdate,
  handleCreate,
  isModalLoading,
  onClose,
  recordFormVals,
  accountReps,
  error,
  isNexisAdmin,
  plansLookUp,
  userTypesLookup,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  useEffect(() => {
    if (isUpdate) {
      recordFormVals.team_obj = recordFormVals.team
        ? {
            value: recordFormVals.team,
            // key: formVals.team,
            label: recordFormVals.team_name,
          }
        : null;

      recordFormVals.created = recordFormVals.created
        ? moment(recordFormVals.created)
        : null;
      recordFormVals.expiry_date = recordFormVals.expiry_date
        ? moment(recordFormVals.expiry_date)
        : null;

      form.setFieldsValue({ ...recordFormVals });
    }
  }, [recordFormVals, isUpdate, form]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const formValues = { ...values };
        formValues.referrer_code_credits =
          formValues.referrer_code_credits || 0;
        if (formValues.team_obj && formValues.team_obj.value) {
          formValues.team = formValues.team_obj.value;
        } else {
          formValues.team = null;
        }
        delete formValues.team_obj;
        /** by default is_nexis is true for nexis admin, even if the checkbox is absent */
        if (isNexisAdmin) {
          formValues.is_nexis = true;
        }
        if (!formValues.max_referrals) formValues.max_referrals = 1;
        if (isUpdate) {
          handleUpdate(recordFormVals.id, formValues);
        } else {
          handleCreate(formValues);
        }
      })
      .catch((errorInfo) => {
        if (errorInfo) {
          console.log(errorInfo);
          message.error("Please fill the mandatory fileds");
          return;
        }
      });
  };

  return (
    <Modal
      title={isUpdate ? "Edit Referrer Code" : "New Referrer Code"}
      visible
      width={800}
      okText={isUpdate ? "Update" : "Create"}
      onOk={handleSubmit}
      confirmLoading={isModalLoading}
      onCancel={onClose}
    >
      <Spin size="medium" spinning={isModalLoading}>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 13 }} form={form}>
          <FormItems
            form={form}
            accountReps={accountReps}
            plansLookUp={plansLookUp}
            userTypesLookup={userTypesLookup}
            isUpdate={isUpdate}
            isNexisAdmin={isNexisAdmin}
          />
          {error && <div className="error-text">{error}</div>}
        </Form>
      </Spin>
    </Modal>
  );
};

const ReferrerCode = (props) => {
  const [listPagination, setListPagination] = useState({
    total: 0,
    pageSize: 50,
    current: 1,
  });
  const { pageSize, current } = listPagination;
  const [isLoading, setIsLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({
    column: "",
    order: "",
  });
  const { column, order } = sortedInfo;
  const [referrerCodeData, setReferrerCodeData] = useState([]);
  const [referrerCodeCount, setReferrerCodeCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [recordFormVals, setRecordFormVals] = useState(null);
  const [accountReps, setAccountReps] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [error, setError] = useState(null);
  const [plansLookUp, setPlansLookUp] = useState([]);
  const [userTypesLookup, setUserTypesLookup] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchReferrerCodeData = useCallback(() => {
    setIsLoading(true);
    const newParams = {
      ...params,
      page_size: pageSize,
      page: current,
    };
    if (searchQuery) newParams.q = searchQuery;
    requestWithAuth("referrer-code", "GET", newParams, null).then(
      (response) => {
        if (response && response.results) {
          setReferrerCodeData(response.results);
          setReferrerCodeCount(response.count);
          setListPagination((prevState) => ({
            ...prevState,
            total: response.count,
          }));
          setIsLoading(false);
        }
      }
    );
  }, [pageSize, current, searchQuery]);

  useEffect(() => {
    fetchAccountReps();
    fetchPlansData();
    fetchUserTypeLookup();
    return () => {
      params = {};
    };
  }, []);

  useEffect(() => {
    fetchReferrerCodeData();

    // return () => {
    //   params = {};
    //   setSortedInfo({
    //     column: "",
    //     order: "",
    //   });
    //   searchQuery = "";
    // };
  }, [fetchReferrerCodeData, column, order]);

  const fetchAccountReps = () => {
    requestWithAuth("field-lookup", "GET", { key: "account_rep" }, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const accountReps = [];
          response.result.map((rep) => {
            accountReps.push({ value: rep.id, text: rep.email });
            return "";
          });
          accountReps.sort();
          setAccountReps(accountReps);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchPlansData = () => {
    requestWithAuth(
      "field-lookup",
      "GET",
      { key: "maxio_public_signup_url" },
      null
    )
      .then((response) => {
        if (response && response.result && response.result.length) {
          setPlansLookUp([...response.result]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchUserTypeLookup = () => {
    const params = {
      key: "user_type",
    };
    requestWithAuth("field-lookup", "GET", params, null)
      .then((response) => {
        if (response && response.result && response.result.length) {
          const userType = [];
          response.result.map((user) => {
            if (user !== "inactive") userType.push({ value: user, text: user });
            return null;
          });
          setUserTypesLookup(userType);
          // userArray = filteredInfoUserType.unshift({ text: 'All', value: 'all' })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formatAccountRep = (accountRepKey) => {
    if (accountReps.length > 0) {
      let val = accountReps.find((rep) => rep.value === accountRepKey);
      return val && val.text ? val.text : "";
    }
    return "";
  };

  const toggleFormModal = (flag) => {
    setShowFormModal(flag);
  };

  const showUpdateForm = (record) => {
    setIsUpdate(true);
    setShowFormModal(true);
    setRecordFormVals({ ...record });
  };

  const handleDelete = (recordId, eventId) => {
    setIsLoading(true);
    requestWithAuth("referrer-code/" + recordId, "DELETE", null, null).then(
      () => {
        message.success("Referrercode deleted");
        setIsLoading(false);
        fetchReferrerCodeData();
      }
    );
  };

  const handleDeleteMultiple = () => {
    const body = {
      code_ids: selectedIds,
    };
    setIsLoading(true);
    requestWithAuth("referrer-code/delete-multiple", "POST", null, body).then(
      (response) => {
        if (response && response.code === 200) {
          message.success("Referrer Code deleted");
          fetchReferrerCodeData();
        } else
          message.error("Not able to delete now. Please try after some time");
        setSelectedIds([]);
        setSelectedRowKeys([]);
        setIsLoading(false);
      }
    );
  };

  const handleCreate = (fields) => {
    setIsModalLoading(true);
    setError(null);
    requestWithAuth("referrer-code", "POST", null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(", "));
          setIsModalLoading(false);
          return;
        }
        if (response) {
          fetchReferrerCodeData();
          setShowFormModal(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
      });
  };

  const handleUpdate = (recordId, fields) => {
    setError(null);
    setIsModalLoading(true);
    requestWithAuth(`referrer-code/${recordId}`, "PUT", null, fields)
      .then((response) => {
        if (response && response.error) {
          setError(Object.values(response.error).join(", "));
          setIsModalLoading(false);
          return;
        } else if (response) {
          fetchReferrerCodeData();
          setShowFormModal(false);
          setIsUpdate(false);
        }
        setIsModalLoading(false);
      })
      .catch((e) => {
        setIsModalLoading(false);
        setShowFormModal(false);
        setIsUpdate(false);
      });
  };

  const handleSearchQuery = (searchValue) => {
    setSearchQuery(searchValue || "");
    setListPagination((prevState) => ({
      ...prevState,
      current: 1,
    }));
  };

  const handleFormReset = () => {
    params = {};
    setSortedInfo({
      column: "",
      order: "",
    });
    setListPagination({
      current: 1,
      pageSize: 50,
    });
    setSearchQuery("");
    //fetchReferrerCodeData();
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    let selectedIds = [];
    selectedIds = selectedRows.map((obj) => {
      return obj.id;
    });
    setSelectedRowKeys(selectedRowKeys);
    setSelectedIds(selectedIds);
  };

  const handleTableChange = (pagination, filtersArg, sorter) => {
    setSelectedRowKeys([]);
    if (sorter && sorter.order && sorter.columnKey) {
      params.ordering =
        sorter.order === "descend" ? "-" + sorter.columnKey : sorter.columnKey;
      setSortedInfo({
        column: sorter.columnKey,
        order: sorter.order,
      });
    } else {
      setSortedInfo({
        column: "",
        order: "",
      });
      delete params.ordering;
    }
    // setParams(params);
    // if (
    //   pagination.pageSize === listPagination.pageSize &&
    //   pagination.current === listPagination.current
    // ) {
    //   fetchReferrerCodeData();
    // }
    setListPagination({
      ...pagination,
      pageSize: pagination.pageSize,
      current: pagination.current,
    });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Referrer Code",
      dataIndex: "referrer_code",
      key: "referrer_code",
      width: 160,
      render: (val) => {
        return <div className="column-style">{val}</div>;
      },
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "owner" && sortedInfo.order,
      render: (val) => {
        return <div className="column-style">{formatAccountRep(val)}</div>;
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      width: 160,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "company" && sortedInfo.order,
      render: (val) => {
        return <div className="column-style">{val || ""}</div>;
      },
    },
    {
      title: "Created",
      width: 120,
      dataIndex: "created",
      key: "created",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "created" && sortedInfo.order,
      render: (val) => {
        return (
          <div className="column-style">{moment(val).format("MM/DD/YY")}</div>
        );
      },
    },
    {
      title: "Expiration Date",
      width: 120,
      dataIndex: "expiry_date",
      key: "expiry_date",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      sortOrder: sortedInfo.column === "expiry_date" && sortedInfo.order,
      render: (val) => {
        return (
          <div className="column-style">
            {val ? moment(val).format("MM/DD/YY") : ""}
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (text, record) => (
        <>
          <Button
            className="row-actions"
            onClick={() => showUpdateForm(record)}
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Popconfirm title="Delete?" onConfirm={() => handleDelete(record.id)}>
            <Button className="row-actions" onClick={(e) => e.preventDefault()}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <TopSection
          placeholder="Search Owner, Company"
          selectedRowKeys={selectedRowKeys}
          handleSearchQuery={handleSearchQuery}
          handleFormReset={handleFormReset}
          toggleCreateForm={toggleFormModal}
          handleDeleteMultiple={handleDeleteMultiple}
          showSearch
          count={referrerCodeCount}
          searchValue={searchQuery}
        />
        <Spin size="medium" spinning={isLoading}>
          <CommonTable
            columns={columns}
            data={referrerCodeData}
            components={components}
            onTableChange={handleTableChange}
            pagination={{ ...listPagination }}
            pageSize={listPagination.pageSize}
            loading={isLoading}
            rowKey={(rec) => `${rec.events_id}${rec.id}`}
            rowSelection={rowSelection}
            selectedRowKeys={selectedRowKeys}
            rowEventHandlers={showUpdateForm}
          />
        </Spin>
      </div>
      {showFormModal && (
        <FormModal
          isUpdate={isUpdate}
          handleUpdate={handleUpdate}
          handleCreate={handleCreate}
          isModalLoading={isModalLoading}
          recordFormVals={recordFormVals}
          accountReps={accountReps}
          plansLookUp={plansLookUp}
          userTypesLookup={userTypesLookup}
          error={error}
          onClose={() => {
            setIsUpdate(false);
            setShowFormModal(false);
            setError(null);
          }}
          isNexisAdmin={props.isNexisAdmin}
        />
      )}
    </>
  );
};

export default ReferrerCode;
